body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.aim {
    background-color: #fff;
    border-radius: 50%;
    position: fixed;
    left: 50%;
    top: 50%;
    height: 8px;
    width: 8px;
    z-index: 10;
    transform: translate(-50%, -50%);
    transition: width 0.2s, height 0.2s, opacity 0.3s;
}

.aim::after {
    content: "";
    display: block;
    position: absolute;
    border: 1px solid #fff;
    border-radius: 50%;
    top: -9px;
    left: -9px;
    height: 24px;
    width: 24px;
    transition: width 0.1s, height 0.1s;
}

.aim span {
    display: none;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 125%;
    position: relative;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    text-align: center;
}

.aim.notVisible {
    opacity: 0;
}

.aim.active {
    height: 84px;
    width: 84px;
}

.aim.active::after {
    height: 100px;
    width: 100px;
    top: -9px;
    left: -9px;
}

.aim.active span {
    display: block;
}

.focus-prompt {
    position: absolute;
    background: linear-gradient(110.4deg, rgba(255, 255, 255, 0.8) 0.62%, rgba(255, 255, 255, 0.72) 0.63%, rgba(255, 255, 255, 0.6) 50.03%, rgba(255, 255, 255, 0.72) 99.44%);
    backdrop-filter: blur(15px);
    border-radius: 30px;
    bottom: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    left: 50%;
    padding: 25px 35px;
    opacity: 0;
    transform: translate(-50%, 15px);
    transition: opacity 0.2s, transform 0.4s;
}

.focus-prompt.active {
    opacity: 1;
    transform: translate(-50%, 0px);
}

.focus-prompt span {
    border: 1px solid #71727F;
    border-radius: 12px;
    padding: 10px 5px;
    color: #71727F;
    margin: 0 5px;
}

.logo {
    position: fixed;
    z-index: 1;
    top: 40px;
    left: 64px;
}

.low-fps-prompt {
    position: absolute;
    background: linear-gradient(110.4deg, rgba(255, 255, 255, 0.8) 0.62%, rgba(255, 255, 255, 0.72) 0.63%, rgba(255, 255, 255, 0.6) 50.03%, rgba(255, 255, 255, 0.72) 99.44%);
    backdrop-filter: blur(15px);
    border-radius: 30px;
    bottom: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    right: 58px;
    padding: 32px;
    opacity: 1;
    transform: translateY(15px);
    transition: opacity 0.2s, transform 0.4s;
    opacity: 0;
    width: 318px;
    z-index: 1000;
}

.low-fps-prompt.active {
    opacity: 1;
    transform: translateY(0px);
}

.low-fps-prompt.hide {
    display: none;
}

.low-fps-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.fps-close {
    cursor: pointer;
}

.low-fps-prompt a {
    color: #23242C;
    text-decoration: underline;
}

.low-fps-prompt h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 16px;
}

.low-fps-prompt p {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
}